.flag-icon-background,
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em;

  &::before {
    content: '\00a0';
  }
}

.flag-icon-fra {
  background-image: url('/assets/flags/fra.svg');
}

.flag-icon-gbr {
  background-image: url('/assets/flags/gbr.svg');
}
