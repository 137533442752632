.p-menubar {
  padding: $horizontalMenuPadding 0;
  color: $horizontalMenuTextColor;

  .p-menubar-root-list {
    outline: 0 none;

    > .p-menuitem {
      @include horizontal-rootmenuitem();
    }
  }

  .p-menuitem {
    @include menuitem();
  }

  .p-submenu-list {
    padding: $verticalMenuPadding;
    background: $overlayMenuBg;
    border: $overlayMenuBorder;
    box-shadow: $overlayMenuShadow;
    width: $menuWidth;

    .p-menuitem-separator {
      border-top: $divider;
      margin: $menuSeparatorMargin;
    }

    .p-submenu-icon {
      font-size: $menuitemSubmenuIconFontSize;
    }
  }
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;

    .p-menubar-button {
      display: flex;
      width: $actionIconWidth;
      height: $actionIconHeight;
      color: $horizontalMenuRootMenuitemIconColor;
      border-radius: $actionIconBorderRadius;
      transition: $actionIconTransition;

      &:hover {
        color: $horizontalMenuRootMenuitemIconHoverColor;
        background: $horizontalMenuRootMenuitemHoverBg;
      }

      &:focus {
        @include focused();
      }
    }

    .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: $verticalMenuPadding;
      background: $overlayMenuBg;
      border: $overlayMenuBorder;
      box-shadow: $overlayMenuShadow;
      width: 100%;

      .p-menuitem-separator {
        border-top: $divider;
        margin: $menuSeparatorMargin;
      }

      .p-submenu-icon {
        font-size: $menuitemSubmenuIconFontSize;
      }

      .p-menuitem {
        width: 100%;
        position: static;

        .p-menuitem-content {
          .p-menuitem-link {
            .p-submenu-icon {
              margin-left: auto;
              transition: transform $transitionDuration;
            }
          }
        }

        &.p-menuitem-active {
          > .p-menuitem-content {
            > .p-menuitem-link {
              > .p-submenu-icon {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }

      .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;

        .p-submenu-icon {
          transition: transform $transitionDuration;
          transform: rotate(90deg);
        }

        .p-menuitem-active {
          > .p-menuitem-content {
            > .p-menuitem-link {
              > .p-submenu-icon {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }

      .p-menuitem {
        width: 100%;
        position: static;
      }

      @include nested-submenu-indents(nth($menuitemPadding, 1), 2, 10);
    }

    &.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
}
