@font-face {
  font-family: 'custom-icons';
  src: url('/assets/fonts/custom-icons.ttf?6hcr8') format('truetype'), url('/assets/fonts/custom-icons.woff?6hcr8') format('woff'),
    url('/assets/fonts/custom-icons.svg?6hcr8#custom-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='custom-icon-'],
[class*=' custom-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'custom-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-icon-sports::before {
  content: '\e900';
}
