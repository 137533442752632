.page-builder {
  display: grid;
  gap: 2rem;

  &__language-selector {
    padding-bottom: 2rem;
  }

  @media (min-width: 1700px) {
    grid-template-columns: 1fr 20rem;

    .page-builder__metadata {
      order: 2;
    }
  }

  &__metadata-aside {
    display: grid;
    gap: 1em;
  }
}
