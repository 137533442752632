.form-fields {
  display: grid;
  gap: 1.5rem;

  &:has(.form-column) {
    grid-auto-flow: column;
  }
}

.form-column {
  grid-template-columns: 1fr;
}

.form-row {
  display: grid;

  & + & {
    margin-top: 1rem;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  &--submit {
    margin-top: 1rem;
    justify-content: end;
  }
}

.form-error {
}
