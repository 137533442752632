$primaryColor: #6366f1 !default;
$primaryLightColor: #c7d2fe !default;
$primaryDarkColor: #4f46e5 !default;
$primaryDarkerColor: #4338ca !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #eef2ff !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: #e0e7ff !default;

@import './_variables';
@import './_fonts';
@import './theme-base/_components';
@import './_extensions';
