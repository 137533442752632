* {
  box-sizing: border-box;
}

body {
  background: var(--surface-ground);
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.spinner-container {
  display: grid;
  place-items: center;
}

.empty-container {
  text-align: center;
  padding: 2rem;
}

.page-header {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  align-items: center;
  margin: 0 0 2rem;

  .page-title {
    margin-bottom: 0;
  }
}

.page-title {
  margin: 0 0 2rem;
  font-weight: 500;
  font-size: 2.4rem;
  color: var(--bluegray-800);
}

a[pButton] {
  text-decoration: none;
}

.media-details-sidebar {
  width: 40%;
  max-width: 680px;
  padding: 2rem;
}

.sm-display-tabs {
  @media screen and (width <= 960px) {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 5;

    &.min-h-screen {
      min-height: 0 !important;
      padding: 0.5rem;

      .navigation-menu__links {
        display: grid;
        gap: 0.25rem;
        grid-auto-flow: column;
      }

      .menu-separator {
        display: none;
      }

      .menu-item {
        position: relative;
        padding: 0.5rem;
        flex-direction: column;
        align-items: center;

        &.back-button {
          display: none;
        }

        .menu-badge {
          position: absolute;
          left: 50%;
          transform: translateX(0.5rem);
        }
      }
    }
  }
}
