@import 'primeicons/primeicons.css';
@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/layout';
@import 'styles/typography';
@import 'styles/form';
@import 'styles/page-builder';
@import 'styles/countries';

html {
  font-family: var(--system-ui);
}

body {
  margin: 0;
}

.cdk-drag-dragging {
  display: block;
  box-shadow: 0 1rem 2rem -0.5re rgba(#000, 0.15);
}
